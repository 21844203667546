import { formatPrice } from "../models/EcommerceProductModel";
export default (price, priceRange, disclaimer) => {
	return price.map((x) => {
		let totalRecurringPrice = x?.swap || x?.downPayment ? x.fullPrice * 24 : undefined;
		let discountedTotalRecurring = x?.swap || x?.downPayment ? x?.salePrice * 24 : undefined;
		let isPriceRange = x?.swap
			? priceRange?.swapPrice
			: x?.downPayment
				? priceRange.downPayment
				: priceRange?.fullPrice;
		return {
			additionalInfo:
				(x?.swap || x?.downPayment) && x.fullPrice
					? `Totalt etter 24 md. ${
							x?.salePrice ? formatPrice(discountedTotalRecurring) : formatPrice(totalRecurringPrice)
						}`
					: undefined,
			extraAdditionalInfo: x?.swap ? (disclaimer ? disclaimer : "Abo. Uten binding kommer i tillegg") : undefined,
			price:
				(isPriceRange && !x?.salePrice ? "Fra: " : "") +
				(x?.salePrice ? "Før: " : "") +
				`${formatPrice(x?.fullPrice)}${x?.swap || x?.downPayment ? " /md." : ""}`,
			productCardPrice: {
				prefix: isPriceRange && !x?.salePrice ? "Fra: " : x?.salePrice ? "Før: " : "",
				price: formatPrice(x?.fullPrice),
				suffix: x?.swap || x?.downPayment ? "/md." : "",
			},
			productCardSalePrice: {
				prefix: x?.salePrice ? `${isPriceRange ? "Fra:" : "Nå:"}` : undefined,
				price: x?.salePrice ? formatPrice(x?.salePrice) : undefined,
				suffix: x?.swap || x?.downPayment ? "/md." : "",
			},

			salePrice: x?.salePrice
				? `${isPriceRange ? "Fra:" : "Nå:"} ${formatPrice(x?.salePrice)}${x?.swap || x?.downPayment ? " /md." : ""}`
				: undefined,
			text: x?.swap ? "SWAP" : x?.downPayment ? "Nedbetaling" : "Betal nå",
		};
	});
};
