<template>
	<LinkWrap
		v-bind="link"
		@click.stop="allowCardClick && $emit('add-to-cart', rawProduct)"
		@mouseenter="preloadVariants"
		ondragstart="return false"
		draggable="false"
	>
		<TnCard
			borderRadius="l"
			shadow="m"
			class="card"
		>
			<!-- Badge -->
			<template
				v-if="label"
				#badge
			>
				<TnBadge
					:size="isMobile ? '2xs' : 's'"
					compact
					:category="badgeCategory"
					class="badge-container"
					>{{ label }}</TnBadge
				>
			</template>
			<!-- Badge -->

			<!-- Image -->
			<div
				class="image-container"
				v-if="loading"
			>
				<TnSkeleton
					height="100%"
					width="100%"
					border-radius="m"
				/>
			</div>
			<div
				v-if="!loading"
				class="image-container"
				@click="buttonVariant && $emit('show-modal')"
			>
				<template v-if="productVariants.length === 0 && image">
					<NuxtImg
						:src="image.md"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
				<template v-else-if="productVariants.length > 0">
					<NuxtImg
						v-for="variant in productVariants"
						:alt="variant.image.alt"
						:src="variant.image.md"
						:loading="variantsPreloaded ? 'eager' : 'lazy'"
						v-show="variant.id === safeCurrentVariant"
						:key="variant.id"
						:modifiers="{ trim: 10 }"
						:height="isMobile ? 100 : 200"
						width="auto"
					/>
				</template>
			</div>
			<!-- Image -->

			<div class="card-content">
				<!-- Heading -->

				<div class="heading">
					<!-- <div class="brand-color"> -->
					<p
						v-if="!loading"
						class="product-brand"
					>
						{{ brand }}
					</p>
					<!-- Color variant -->
					<div class="title-variant-wrapper">
						<ul
							class="variant-selector"
							v-if="!hideVariant && !loading"
						>
							<li
								class="variant"
								:class="{ active: currentVariant === variant.id }"
								@mouseover="changeVariant(variant)"
								v-for="variant in productVariants"
								:style="`background-color: ${variant.colorCode || variant.colorcode}`"
								:title="variant.color"
								:key="variant.id"
							></li>
							<li
								v-show="moreVariants"
								class="variant more"
							>
								+{{ moreVariants }}
							</li>
						</ul>
					</div>
					<!-- Color variant -->
					<!-- </div> -->
					<TnSkeleton
						height="16px"
						v-if="loading"
						:width="isMobile ? '45%' : '80%'"
						class="product-brand margin-bottom-m"
						border-radius="m"
					/>

					<p
						v-if="!loading"
						class="product-title"
					>
						{{ title }}
					</p>
					<TnSkeleton
						height="20px"
						v-if="loading"
						width="80%"
						class="product-title margin-bottom-xs"
						border-radius="m"
					/>

					<!-- TODO:- Don't show model until discussed with designer -->
					<p
						class="product-model"
						v-if="!loading && useModelNameCategory && false"
					>
						{{ model === "Model" ? "" : model }}
					</p>
				</div>
				<!-- Heading -->

				<!-- Price -->

				<div
					v-for="(tab, index) in tabs"
					:key="index"
					class="price-container"
					v-show="activeTab(index)"
				>
					<div>
						<div
							class="price"
							v-if="loading"
						>
							<div class="tab-price">
								<TnSkeleton
									:height="isMobile ? '21' : '31'"
									width="50%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
							<div style="display: flex; flex-direction: column">
								<TnSkeleton
									height="12"
									width="65%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
								<TnSkeleton
									height="12"
									width="35%"
									border-radius="m"
									style="margin-bottom: 5px"
								/>
							</div>
						</div>
						<div
							v-if="!loading"
							class="price"
						>
							<div
								class="tab-price"
								v-if="tab.salePrice"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
									>{{ cleanPrefix(tab?.productCardSalePrice?.prefix) }}
								</TnParagraph>
								<TnHeading
									bold
									size="m"
									responsive
								>
									{{ verifySalePrice(tab)?.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
									>{{ verifySalePrice(tab)?.suffix }}</TnParagraph
								>
							</div>
							<div
								class="tab-price"
								v-if="tab?.price && !tab.salePrice"
							>
								<TnParagraph
									bold
									size="xs"
									responsive
									class="tab-price-prefix"
									>{{ cleanPrefix(tab?.productCardPrice?.prefix) }}
								</TnParagraph>
								<TnHeading
									bold
									style="margin-bottom: -5px"
									size="m"
									responsive
								>
									{{ verifyPrice(tab)?.price }}
								</TnHeading>

								<TnParagraph
									bold
									size="xs"
									responsive
									>{{ verifyPrice(tab)?.suffix }}</TnParagraph
								>
							</div>
							<div
								class="tab-price"
								v-show="tab?.price && tab.salePrice"
							>
								<TnParagraph
									class="strike-through"
									size="xs"
									responsive
									>{{ tab?.productCardPrice?.prefix }} {{ tab?.productCardPrice?.price }}
									{{ tab?.productCardPrice?.suffix }}</TnParagraph
								>
							</div>

							<TnParagraph
								v-if="tab.additionalInfo && !hideAdditional && !onlyFullPriceAvailableDisclaimer"
								class="disclaimer-text"
								size="xs"
							>
								{{ tab.additionalInfo }}
							</TnParagraph>
						</div>
					</div>
					<TnParagraph
						v-if="onlyFullPriceAvailableDisclaimer && !loading"
						class="full-price-disclaimer-text disclaimer-text"
						size="xs"
					>
						{{ onlyFullPriceAvailableDisclaimer }}
					</TnParagraph>
				</div>
				<!-- Price -->
			</div>

			<!-- Storage availablity -->
			<TnSkeleton
				height="20px"
				v-if="loading"
				width="80%"
				border-radius="m"
			/>

			<MainStock
				v-if="!loading && !buttonVariant"
				:main-stock="mainStock"
				:size="isMobile ? '12px' : '14px'"
				:unVerifiedDeliveryText="unVerifiedDeliveryText"
				:thresholds="thresholds"
				class="stock"
				@isUnavailable="setUnavailable"
				grid
				:product="selectedProduct"
			/>
			<div
				v-else-if="!loading && buttonVariant"
				@click.stop
			>
				<TnCounter
					v-if="numberOfItems > 0"
					class="counter"
					:startValue="numberOfItems"
					:maxAmount="maxAmount"
					:key="id + numberOfItems"
					size="xs"
					@add="$emit('add-to-cart', rawProduct)"
					@subtract="$emit('remove-from-cart', rawProduct)"
				/>
				<TnButton
					class="add-button"
					v-else
					@click.stop="$emit('add-to-cart', rawProduct)"
					size="xs"
				>
					Legg til
				</TnButton>
			</div>
			<!-- Storage availablity -->
		</TnCard>
	</LinkWrap>
</template>

<script>
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel";
import IsTestMixin from "~/mixins/IsTestMixin";
import striptags from "striptags";
import PriceModel from "./PriceModel";
import moment from "moment";
import { mapState } from "vuex";

const asSafeString = (str) => {
	if (!str) return "";
	return str
		.toLowerCase()
		.replace(/å/g, "a")
		.replace(/æ/g, "a")
		.replace(/ø/g, "o")
		.replace(/\+/g, "plus")
		.replace(/[^\w\s]/g, "")
		.replace(/\s/g, "-");
};

export default defineNuxtComponent({
	props: {
		badgeCategory: {
			type: String,
		},
		selectedPaymentOption: {
			type: Number,
		},
		unVerifiedDeliveryText: {
			type: String,
		},
		product: {
			type: Object,
		},
		rawProduct: {
			type: Object,
		},
		addToCart: {
			type: Boolean,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		hideRating: {
			type: Boolean,
		},
		numberOfItems: {
			type: Number,
		},
		maxAmount: {
			type: Number,
		},
		hideDescription: {
			type: Boolean,
		},
		hideSwap: {
			type: Boolean,
		},
		buttonVariant: {
			type: Boolean,
		},
		hideVariant: {
			type: Boolean,
		},
		hideAdditional: {
			type: Boolean,
		},
		hidePayNow: {
			type: Boolean,
		},
		defaultTabIndex: {
			type: Number,
			default: 0,
		},
		useNewProductPage: {
			// TODO: Remove when 1.5 is fully released
			type: Boolean,
		},
		noLink: {
			// Used when you don't want to use productLink but handle the click event some other way
			type: Boolean,
		},
		quickAdd: {
			// Adds an inline "add to cart" button
			type: Boolean,
		},
		swapSubscriptionDisclaimerText: {
			type: String,
		},
	},

	mixins: [IsTestMixin],

	data() {
		return {
			tabIndex: 0,
			currentVariant: this.product.productId,
			variantsPreloaded: false,
		};
	},

	beforeMount() {
		this.tabIndex = this.selectedPaymentOption || this.defaultTabIndex;
	},

	mounted() {
		if (this.productVariants?.length > 0 && !this.hideVariant) {
			this.currentVariant = this.productVariants[0].id;
		}
	},

	watch: {
		selectedPaymentOption: {
			handler(value) {
				this.tabIndex = value;
			},
			immediate: true,
		},
	},

	computed: {
		...mapState("ecommerce", ["categoriesThatRequireStock"]),
		onlyFullPriceAvailableDisclaimer() {
			if (this.selectedPaymentOption === 1 && !this.tabs?.[1]?.productCardPrice?.price) {
				return "Kun fullpris tilgjengelig";
			} else return false;
		},
		mainStock() {
			return "Auto";
		},
		isMobile() {
			const mobileBreakpoint = 768;
			return window?.innerWidth < mobileBreakpoint;
		},
		thresholds() {
			return [];
		},
		title() {
			const categories = ["Nettbrett", "Smartklokker"];
			if (categories.includes(this.product.category && this.model !== "Model")) {
				return this.product.productName.replace(new RegExp([this.brand, this.model].join("|"), "gi"), "").trim();
			}
			return this.product.productName.replace(new RegExp(this.brand, "i"), "").trim();
		},
		model() {
			return this.product?.model ?? "Model";
		},
		brand() {
			if (this.product?.brand) {
				return this.product?.brand;
			}
			return false;
		},
		id() {
			return this.product.productId;
		},
		label() {
			return this.memberPrice?.badge ? this.memberPrice?.badge : this.product.productLabel;
		},
		imageHeight() {
			if (this.product.class === "xl") return 359 * 2;
			return 175 * 2;
		},
		image() {
			return (
				this.product.productImage?.image_md?.src &&
				ResponsiveImageViewModel(this.product.productImage, undefined, this.imageHeight)
			);
		},
		averageRating() {
			return this.product.rating || 0;
		},
		fromApi() {
			return !!this.product.productId;
		},
		description() {
			return this.product.productDescription && striptags(this.product.productDescription.trim().split("\n")[0]);
		},
		priceRange() {
			const fullPriceRange = new Set(this.prices?.fullPrice?.map((x) => x.fullPrice)).size !== 1;
			const swapPriceRange = new Set(this.prices?.swapPrice.map((x) => x.fullPrice)).size !== 1;
			const downPaymentPriceRange = new Set(this.prices?.downPaymentPrice?.map((x) => x.fullPrice)).size !== 1;
			return {
				fullPrice: fullPriceRange,
				swapPrice: swapPriceRange,
				downPayment: downPaymentPriceRange,
			};
		},
		lowestPrice() {
			return this.prices?.fullPrice?.[0];
		},
		lowestSwapPrice() {
			return this.prices?.swapPrice?.[0];
		},
		lowestDownPaymentPrice() {
			return this.prices?.downPaymentPrice?.[0];
		},
		prices() {
			return {
				fullPrice: this.product?.variants
					?.map((x) => {
						return {
							swap: false,
							downPayment: false,
							fullPrice: x.priceWithVat,
							salePrice:
								this.activeMemberPrice(x.memberPrice) && x.memberPrice?.prices?.withVat?.discount > 0
									? x.memberPrice?.prices.withVat.memberPrice
									: x.salePriceWithVat || undefined,
						};
					})
					?.sort(
						(a, b) =>
							(typeof a.salePrice === "number" ? a.salePrice : a.fullPrice) -
							(typeof b.salePrice === "number" ? b.salePrice : b.fullPrice),
					),
				swapPrice: this.product?.variants
					?.filter((x) => x.swapMonthlyPrice)
					.map((x) => {
						return {
							swap: x.swapMonthlyPrice ? true : undefined,
							downPayment: false,
							fullPrice: x.swapMonthlyPrice,
							salePrice: x.swapDiscountedMonthlyPrice || undefined,
						};
					})
					?.sort(
						(a, b) =>
							(typeof a.salePrice === "number" ? a.salePrice : a.fullPrice) -
							(typeof b.salePrice === "number" ? b.salePrice : b.fullPrice),
					),
				downPaymentPrice: this.isDownPayment
					? this.product?.variants
							.map((x) => {
								return {
									swap: false,
									downPayment: true,
									fullPrice: x.priceWithVat / this.installmentsInMonths,
									salePrice:
										this.activeMemberPrice(x.memberPrice) && x.memberPrice?.prices
											? x.memberPrice?.prices.withVat.memberPrice / this.installmentsInMonth
											: x.salePriceWithVat / this.installmentsInMonth || undefined,
								};
							})
							?.sort(
								(a, b) =>
									(typeof a.salePrice === "number" ? a.salePrice : a.fullPrice) -
									(typeof b.salePrice === "number" ? b.salePrice : b.fullPrice),
							)
					: [],
			};
		},
		lowestPriceForSingleProduct() {
			if (this.variants?.length === 0) {
				return {
					fullPrice: this.activeMemberPrice(this.product?.memberPrice)
						? this.product.memberPrice.prices.withVat.normalPrice
						: this.product?.price,
					salePrice: this.activeMemberPrice(this.product?.memberPrice)
						? this.product.memberPrice.prices.withVat.memberPrice
						: this.product?.salePrice,
					swap: false,
					downPayment: false,
				};
			}
			return "";
		},
		lowestSwapPriceForSingleProduct() {
			if (this.variants?.length === 0) {
				if (this.isDownPayment) {
					return {
						fullPrice: this.product?.price / this.installmentsInMonths,
						salePrice: this.product?.salePrice / this.installmentsInMonths,
						swap: false,
						downPayment: true,
					};
				}
				if (this.product?.swapMonthlyPrice) {
					return {
						fullPrice: this.product?.swapMonthlyPrice,
						salePrice: this.product?.swapDiscountedMonthlyPrice,
						swap: true,
						downPayment: false,
					};
				}
			}
			return "";
		},
		isDownPayment() {
			return this.product?.installmentsInMonths ? true : false;
		},
		installmentsInMonths() {
			return isNaN(parseInt(this.product?.installmentsInMonths))
				? undefined
				: parseInt(this.product?.installmentsInMonths);
		},
		formattedPrices() {
			if ((this.variants && this.variants?.length <= 0) || !this.product?.variants?.find((x) => x.priceWithVat)) {
				if (this.lowestDownPaymentPrice || this.lowestDownPaymentPrice) {
					return PriceModel(
						[this.lowestPriceForSingleProduct, this.lowestSwapPriceForSingleProduct || this.lowestDownPaymentPrice],
						false,
						this.extraAdditionalInfo,
					);
				} else if (this.lowestSwapPriceForSingleProduct) {
					return PriceModel(
						[this.lowestPriceForSingleProduct, this.lowestSwapPriceForSingleProduct],
						false,
						this.extraAdditionalInfo,
					);
				} else {
					return PriceModel([this.lowestPriceForSingleProduct], false, this.extraAdditionalInfo);
				}
			}
			if (this.product.category === "Smartklokker") {
				if (this.lowestDownPaymentPrice) {
					return PriceModel([this.lowestPrice, this.lowestDownPaymentPrice], this.priceRange, this.extraAdditionalInfo);
				} else {
					return PriceModel([this.lowestPrice], this.priceRange, this.extraAdditionalInfo);
				}
			}
			if (this.prices.swapPrice && this.lowestSwapPrice) {
				return PriceModel([this.lowestPrice, this.lowestSwapPrice], this.priceRange, this.extraAdditionalInfo);
			}
			return PriceModel([this.lowestPrice], this.priceRange, this.extraAdditionalInfo);
		},
		price() {
			if (this.memberPrice?.memberPrice) {
				const fullPrice = this.product?.productPrice?.find((x) => x.text === "Betal nå");
				const swapPrice = this.product?.productPrice?.find((x) => x.text === "SWAP");
				if (fullPrice && swapPrice) {
					return [{ ...fullPrice, salePrice: this.memberPrice?.memberPrice }, { ...swapPrice }];
				}
			}
			if (this.$store.getters["ecommerce/segment"] === "business") {
				return this.product.productPrice;
			} else return this.product.productPriceWithVat;
		},
		memberPrice() {
			if (!this.product?.memberPrice?.prices) return;
			const today = moment();
			const activeMemberPrice = today.isBetween(
				moment(this.product?.memberPrice?.startDate),
				moment(this.product?.memberPrice?.endDate),
				null,
				"[]",
			);
			if (!activeMemberPrice) return undefined;
			if (this.$store.getters["ecommerce/segment"] === "business" && this.product?.memberPrice) {
				return {
					...this.product.memberPrice.prices.withoutVat,
					badge: this.product.memberPrice.campaignData.badgeText,
				};
			}
			return { ...this.product.memberPrice.prices.withVat, badge: this.product.memberPrice.campaignData.badgeText };
		},
		additionalInfo() {
			return this.product.productAdditionalInfo;
		},
		extraAdditionalInfo() {
			return this.swapSubscriptionDisclaimerText || this.product.productExtraAdditionalInfo;
		},
		variants() {
			return this.product.variants || [];
		},
		safeCurrentVariant() {
			if (this.currentVariant) return this.currentVariant;
			if (this.product.variants?.length > 0) {
				if (!this.product.variants.some((v) => v.id === this.product.productId)) return this.product.variants[0].id;
			}
			return undefined;
		},
		tabTexts() {
			if (!Array.isArray(this.tabs)) return null;

			const tabTexts = this.tabs
				.map((tab) => {
					return tab.text;
				})
				.filter((tabText) => tabText);

			// Only return tab texts if there are more than 1 tab
			return tabTexts.length > 1 ? tabTexts : null;
		},
		link() {
			if (this.noLink) return { href: "javascript:" };
			if (this.product.productLink) {
				const urlSegments = [
					this.product.category,
					this.product.brand || this.selectedProduct.brand,
					this.selectedProduct.title || this.selectedProduct.rawProductName,
					this.selectedProduct.condition || this.product.condition,
				]
					.map(asSafeString)
					.filter((s) => s);

				let url = `/${urlSegments.join("/")}/`;
				if (this.$store.getters["ecommerce/segment"] === "business") url = "/bedrift" + url;

				return { to: url };
			}
			return { href: "javascript:" };
		},
		swapIsSelected() {
			return this.tabTexts && this.tabTexts[this.tabIndex]?.startsWith("SWAP");
		},
		tabs() {
			let price = this.formattedPrices;
			if (!Array.isArray(price) || (Array.isArray(price) && price?.length === 0)) {
				price = [
					{
						price: this.price,
						additionalInfo: this.additionalInfo,
						extraAdditionalInfo: this.extraAdditionalInfo,
					},
				];
			} else if (this.swapSubscriptionDisclaimerText) {
				return price.map((x) => {
					if (x.text && x.text?.toLowerCase() === "swap") {
						return { ...x, extraAdditionalInfo: this.swapSubscriptionDisclaimerText };
					}
					if (x.text && x.text?.toLowerCase() === "nedbetaling") {
						return { ...x, extraAdditionalInfo: this.extraAdditionalInfo };
					} else return x;
				});
			}
			return price;
		},
		selectedProduct() {
			return {
				...this.product,
				productId: this.safeCurrentVariant || this.product.productId,
				title: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.title,
				brand: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.brand,
				condition: this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.condition,
				inventory:
					this.variants.length > 0
						? this.variants.find((variant) => variant.id === this.safeCurrentVariant)?.inventory
						: this.product.inventory,
			};
		},
		productVariants() {
			if (this.variants.length > 1) {
				const variants = this.variants.map((variant) => {
					return {
						...variant,
						image: variant.image && ResponsiveImageViewModel(variant.image, undefined, this.imageHeight),
						link: variant.link && LinkViewModel(variant.link),
					};
				});

				const sortedVariants = [variants.find((v) => v.id === this.id), ...variants.filter((v) => v.id !== this.id)]
					.filter((v) => v)
					.sort((a, b) => b.inventory?.[0]?.amount - a.inventory?.[0]?.amount);

				let uniqueVariants = [];
				for (const item of sortedVariants) {
					const isDuplicate = uniqueVariants.find((obj) => obj.colorCode === item.colorCode);
					if (!isDuplicate) {
						uniqueVariants.push(item);
					}
				}

				uniqueVariants = uniqueVariants.sort((a, b) => a?.priority - b?.priority);

				if (this.categoriesThatRequireStock?.includes(this.product?.category)) {
					const sortedUniqueVariantsWithInventory = uniqueVariants
						.filter((v) => v.inventory?.[0]?.amount > 0)
						.sort((a, b) => a.priority - b.priority);
					const sortedUniqueVariantsWithoutInventory = uniqueVariants
						.filter((v) => v.inventory?.[0]?.amount === 0 || !v.inventory)
						.sort((a, b) => a.priority - b.priority);
					uniqueVariants = [...sortedUniqueVariantsWithInventory, ...sortedUniqueVariantsWithoutInventory];
				}

				return uniqueVariants.length > 3 ? uniqueVariants.slice(0, 3) : uniqueVariants;
			} else return [];
		},
		moreVariants() {
			if (this.variants.length > 1) {
				const variants = this.variants.map((variant) => {
					return {
						...variant,
						image: variant.image && ResponsiveImageViewModel(variant.image, undefined, this.imageHeight),
						link: variant.link && LinkViewModel(variant.link),
					};
				});

				const sortedVariants = [variants.find((v) => v.id === this.id), ...variants.filter((v) => v.id !== this.id)]
					.filter((v) => v)
					.sort((a, b) => b.inventory?.[0]?.amount - a.inventory?.[0]?.amount);

				let uniqueVariants = [];
				for (const item of sortedVariants) {
					const isDuplicate = uniqueVariants.find((obj) => obj.colorCode === item.colorCode);
					if (!isDuplicate) {
						uniqueVariants.push(item);
					}
				}

				uniqueVariants = uniqueVariants.sort((a, b) => a?.priority - b?.priority);

				return uniqueVariants.length - this.productVariants.length;
			} else return 0;
		},
		productAlreadyInCart() {
			return this.$store.getters["shopping-cart/contextItem"]?.subItems.some((si) => si.data.id === this.id);
		},
		allowCardClick() {
			return !this.buttonVariant && !this.numberOfItems > 0;
		},
		useModelNameCategory() {
			return this.product.category === "Tilbehør" || this.product.category === "Smartklokker";
		},
	},

	methods: {
		activeMemberPrice(memberPriceObject) {
			if (!memberPriceObject || (memberPriceObject && Object.keys(memberPriceObject)?.length < 1)) return false;
			const today = moment();
			const isBetween = today.isBetween(
				moment(memberPriceObject?.startDate),
				memberPriceObject?.endDate ? moment(memberPriceObject.endDate) : moment(),
				null,
				"[]",
			);
			return isBetween;
		},
		verifySalePrice(tab) {
			if (tab.productCardSalePrice?.price == undefined && tab?.text == "Nedbetaling") {
				const otherTab = this.tabs.find((x) => x.text !== "Nedbetaling");
				return otherTab.productCardSalePrice;
			}
			if (tab.productCardSalePrice?.price == undefined && tab?.text == "SWAP") {
				const otherTab = this.tabs.find((x) => x.text !== "SWAP");
				return otherTab.productCardSalePrice;
			}
			if (tab.productCardSalePrice?.price == undefined && tab.text == "Betal nå") {
				const otherTab = this.tabs.find((x) => x.text !== "Betal nå");
				return otherTab.productCardSalePrice;
			}
			return tab.productCardSalePrice;
		},
		verifyPrice(tab) {
			if (tab?.productCardPrice?.price == undefined && tab?.text == "Nedbetaling") {
				const otherTab = this.tabs.find((x) => x.text !== "Nedbetaling");
				return otherTab?.productCardPrice;
			}
			if (tab?.productCardPrice?.price == undefined && tab?.text == "SWAP") {
				const otherTab = this.tabs.find((x) => x.text !== "SWAP");
				return otherTab?.productCardPrice;
			}
			if (tab?.productCardPrice?.price == undefined && tab.text == "Betal nå") {
				const otherTab = this.tabs.find((x) => x.text !== "Betal nå");
				return otherTab?.productCardPrice;
			}
			return tab?.productCardPrice;
		},
		cleanPrefix(prefix) {
			return prefix?.toString()?.replace(/:/g, "");
		},
		setUnavailable(value) {
			this.isUnavailable = value;
		},
		selectTab(event) {
			this.tabIndex = event.index;
		},
		activeTab(index) {
			if (this.tabs?.length === 1) {
				return true;
			}
			return index === this.tabIndex;
		},
		changeVariant(variant) {
			this.currentVariant = variant.id;
		},
		preloadVariants() {
			this.variantsPreloaded = true;
		},
	},
});
</script>

<style lang="scss" scoped>
.card {
	max-width: 302px;
	height: 530px;
	display: grid !important;
	grid-template-rows: 261px auto 30px;
	flex-direction: column;
	padding: $spacing-3xl $spacing-m $spacing-m $spacing-m;

	.badge-container {
		z-index: 1;

		// Text-size from TnBadge not working correctly
		@include breakpoint(mobile) {
			font-size: 12px;
		}
	}

	.image-container {
		width: 100%;
		height: 261px;
		position: relative;
		object-fit: contain;
		display: grid;
		place-items: center;

		img {
			width: 100%;
			height: 200px;
			object-fit: contain;
			transition: all 0.1s ease-in-out;

			@include breakpoint(mobile) {
				width: 100%;
				height: 100px;
			}
		}

		@include breakpoint(mobile) {
			width: 100%;
			height: 100px;
		}
	}

	&-content {
		display: grid;
		grid-template-rows: calc(74px + $spacing-l) auto;

		@include breakpoint(mobile) {
			grid-template-rows: calc(86px + $spacing-s) auto;
		}
	}

	.heading {
		display: grid;
		grid-template-areas:
			"brand color"
			"title title";
		grid-template-rows: 22px 52px auto;
		margin-top: $spacing-l;

		.variant-selector {
			display: flex;
			gap: 2px;
			transform: translate(3px, 2px);

			.variant {
				width: 18px;
				height: 18px;
				display: flex;
				border-radius: 50%;
				border: 2px solid white;
				box-shadow: 0 2px 3px 0 #0003 inset;
				transition: transform 0.3s ease;

				&.active:not(.more) {
					outline: 2px solid $color-cta-active;
				}

				&.more {
					width: auto;
					border-radius: 0;
					box-shadow: none;
					position: relative;
					top: -1px;

					@include font-text-s;
				}

				@include breakpoint(mobile) {
					width: 14px;
					height: 14px;
				}
			}
		}

		.title-variant-wrapper {
			grid-area: color;
			display: flex;
			align-items: center;
			align-self: center;
			justify-content: flex-end;
			height: 20px;

			@include breakpoint(mobile) {
				height: 18px;
			}

			@include breakpoint(mobile) {
				justify-content: flex-start;
			}
		}

		.product {
			&-brand {
				white-space: nowrap;
				overflow: hidden;
				grid-area: brand;
				align-self: center;

				@include font-title-2xs;
			}

			&-title {
				max-height: 52px;
				height: max-content;
				overflow: hidden;
				grid-area: title;

				@include font-title-bold-xs;

				@include breakpoint(mobile) {
					height: 32px;
				}
			}

			&-model {
				white-space: nowrap;
				overflow: hidden;
				grid-area: model;

				@include font-title-2xs;
			}

			@include breakpoint(mobile) {
				&-brand {
					@include font-text-2xs;
				}

				&-title {
					@include font-text-bold-2xs;
				}

				&-model {
					grid-area: color;

					@include font-text-2xs;
				}
			}
		}

		@include breakpoint(mobile) {
			grid-template-rows: 16px 32px auto;
			grid-template-areas:
				"brand brand"
				"title title"
				"color color";
		}

		@include breakpoint(mobile) {
			margin-top: $spacing-m;
		}
	}

	.price {
		.tab-price {
			color: $color-primary-mid;
			display: flex;
			align-items: baseline;

			&-prefix {
				margin-right: $spacing-xs;
			}

			p:not(.strike-through) {
				color: $color-primary-mid;
			}
		}

		.disclaimer-text-extra {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.disclaimer-text {
			font-size: 14px !important;
			color: $color-neutrals-600-shade;

			@include breakpoint(mobile) {
				font-size: 12px !important;
			}
		}

		.strike-through {
			color: $color-neutrals-600-shade;
			text-decoration: line-through;
		}
	}

	.stock {
		font-size: 14px !important;

		@include breakpoint(mobile) {
			font-size: 12px !important;
		}
	}

	@include breakpoint(mobile) {
		grid-template-rows: 100px auto 36px;
	}

	@include breakpoint(mobile) {
		padding: $spacing-2xl $spacing-s $spacing-s $spacing-s;
	}

	@include breakpoint(mobile) {
		height: 340px;

		.title-variant-wrapper {
			display: block;
		}

		.heading {
			.brand-color {
				display: flex;
				margin-bottom: $spacing-xs;
				align-items: center;
			}

			p {
				text-wrap: wrap;
			}
		}

		.price {
			font-weight: bold;
		}
	}
}

.price-container {
	position: relative;

	.full-price-disclaimer-text {
		position: absolute;
		bottom: $spacing-xs;
		margin-left: 6px;
		color: $color-primary-mid !important;
	}
}

.disclaimer-text {
	font-size: 14px !important;
	color: $color-neutrals-600-shade;

	@include breakpoint(mobile) {
		font-size: 12px !important;
	}
}

a {
	width: 280px;

	* {
		max-width: calc(280px - calc($spacing-m * 2));

		@include breakpoint(mobile) {
			max-width: calc(165px - calc($spacing-s * 2));
		}
	}

	@include breakpoint(mobile) {
		width: 165px;
	}
}
</style>
